<template>
  <div class="city">
    <div class="topText">
      <div class="title">{{ city.title }}</div>
    </div>
    <div class="conBox">
      <img class="imgBlock" :src="city.img">
      <div class="right-box">
        <div class="bgBlock" />
        <div class="textBlock">
          <div class="text">
            <div>{{ city.content }}</div>
          </div>
          <div class="line" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'City',
  props: {
    city: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.city {
  .topText {
    height: 200px;
  }
  .conBox {
    width: 1280px;
    height: 402px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    .right-box {
      position: relative;
      .textBlock {
        position: absolute;
        top: 50%;
        right: 120px;
        transform: translateY(-50%);
        width: 278px;
        color: #6A6E77;
        font-size: 16px;
        line-height: 28px;
        text-indent: 34px;
        letter-spacing: .2px;
        .line {
          position: absolute;
          right: 0;
          width: 42px;
          height: 5px;
          background: #538DFC;
        }
        .text {
          margin-bottom: 12px;
        }
      }
      .bgBlock {
        position: absolute;
        top: 0;
        right: 0;
        width: 330px;
        height: 100%;
        background: rgba(234,240,246,0.60);
        box-shadow: 4px 2px 15px 15px rgba(233,239,245,0.20);
        border-radius: 5px;
      }
    }
    .imgBlock {
      width: 833px;
      height: 402px;
    }
  }
}
</style>
