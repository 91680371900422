<!-- 无数据状态 -->
<template>
  <div class="empty">
    <div class="empty-title">暂无数据</div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.empty {
  width: 100%;
  height: 100px;
  .empty-title {
    opacity: .7;
    font-size: 18px;
    line-height: 100px;
    text-align: center;
  }
}
</style>
