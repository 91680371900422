<template>
  <div class="industry">
    <div class="topText">
      <div class="title">{{ industry.title }}</div>
    </div>
    <div class="conBox">
      <div class="left-box">
        <div class="bgBlock" />
        <div class="textBlock">
          <div class="line" />
          <div class="text">
            <!-- <div v-for="(item, index) in industry.content" :key="index">
              {{ item }}
            </div> -->
            <p v-html="industry.content" />
          </div>
        </div>
      </div>
      <img class="imgBlock" :src="industry.img">
    </div>
  </div>
</template>
<script>
export default {
  name: 'Industry',
  props: {
    industry: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.industry {
  .topText {
    height: 204px;
  }
  .conBox {
    width: 1276px;
    height: 462px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    .left-box {
      flex: 1;
      position: relative;
      .textBlock {
        position: absolute;
        left: 0;
        top: 60px;
        width: 605px;
        color: #6A6E77;
        font-size: 16px;
        line-height: 28px;
        text-indent: 34px;
        letter-spacing: 1px;
        .text {
          p {
            font-family: 'siyuan-face'!important;
          }
        }
        .line {
          margin-bottom: 22px;
          width: 42px;
          height: 5px;
          background: #538DFC;
        }
      }
      .bgBlock {
        position: absolute;
        top: 34px;
        left: 397px;
        width: 270px;
        height: 400px;
        background: rgba(234,240,246,0.60);
        border-radius: 5px;
        box-shadow: 4px 2px 15px 15px rgba(233,239,245,0.20);
      }
    }
    .imgBlock {
      position: absolute;
      right: 0;
      top: 0;
      width: 580px;
      height: 462px;
    }
  }
}
</style>
