<template>
  <div class="construct">
    <div class="topText">
      <div class="title">{{ construct.title }}</div>
    </div>
    <div class="conBox">
      <div class="topRow">
        <div class="bgBlock" />
        <div class="textBlock">
          <div class="text">
            <div>{{ construct.content }}</div>
          </div>
          <div class="line" />
        </div>
      </div>
      <div class="imgBlock">
        <img class="img" :src="construct.img">
        <div class="textList">
          <div v-for="(item, index) in construct.steps" :key="index">
            <div class="li">
              <div class="tit">{{ item.title }}</div>
              <div class="text">{{ item.content }}</div>
            </div>
            <div v-if="index !== (construct.steps.length - 1)" class="down">
              <img src="@/assets/solution/down.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Construct',
  props: {
    construct: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.construct {
  .topText {
    height: 170px;
    // .title {
    //   padding-top: 66px;
    // }
  }
  .conBox {
    width: 1280px;
    margin: 0 auto;
    .topRow {
      margin-bottom: 46px;
      margin-left: 30px;
      width: 1220px;
      position: relative;
      height: 182px;
      .textBlock {
        position: absolute;
        left: 225px;
        top: 46px;
        width: 930px;
        color: #6A6E77;
        font-size: 16px;
        line-height: 28px;
        text-indent: 34px;
        letter-spacing: .2px;
        .line {
          width: 42px;
          height: 5px;
          background: #538DFC;
        }
        .text {
          margin-bottom: 12px;
        }
      }
      .bgBlock {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 580px;
        height: 182px;
        background: rgba(234,240,246,0.60);
        border-radius: 5px;
        box-shadow: 4px 2px 15px 15px rgba(233,239,245,0.20);
      }
    }
    .imgBlock {
      display: flex;
      .img {
        width: 962px;
        height: 494px;
        margin-right: 47px;
      }
      .textList {
        width: 230px;
        .li {
          .tit {
            margin-bottom: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 135px;
            height: 34px;
            background: #2e7cb6;
            border-radius: 3px;
            font-size: 18px;
            color: #fff;
            font-family: 'siyuan-medium' !important;
          }
          .text {
            margin-bottom: 11px;
            font-size: 16px;
            color: #666;
          }
        }
        img {
          margin-left: 44px;
          margin-bottom: 22px;
          width: 45px;
          height: 48px;
        }
      }
    }
  }
}
</style>
