<!-- 解决方案 -->
<template>
  <div class="wrapper pageBox">
    <div v-if="isShow" class="banner">
      <el-carousel>
        <el-carousel-item v-for="(item, index) in banner" :key="'banner' + index">
          <div class="div">
            <img :src="item.img">
            <div class="bannerText">
              <div class="title">{{ item.title }}</div>
              <div class="text" style="width: 980px">{{ item.content }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 行业概述 -->
    <div v-for="(item, index) in industry" :key="'industry' + index">
      <Industry v-if="isShow" :industry="item" />
    </div>
    <!-- 智慧城市解决方案全景图 -->
    <div v-for="(item, index) in solutionCase" :key="'solutionCase' + index">
      <Pano v-if="isShow" :table-data="item" />
    </div>
    <!-- 智慧城市整体框架 -->
    <div v-for="(item, index) in city" :key="'city' + index">
      <City v-if="isShow" :city="item" />
    </div>
    <!-- 建设路线 -->
    <div v-for="(item, index) in construct" :key="'construct' + index">
      <Construct v-if="isShow" :construct="item" />
    </div>
    <!-- 客户案例 -->
    <div v-for="(item, index) in customerCase" :key="'customerCase' + index">
      <Case v-if="isShow" :table-data="item" />
    </div>
    <div class="connect">
      <Empty v-if="!isShow" />
    </div>
    <div class="foot">
      <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/footerBg.png">
      <div class="conBox">
        <div class="texts">
          <div class="text">合作</div>
          <div class="text">开放</div>
          <div class="text">共赢</div>
        </div>
        <div class="more-box">
          <div class="more" @click="$router.push('/about/introduce?id=39')">了解更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Industry from './components/industry.vue';
import Pano from './components/pano.vue';
import City from './components/city.vue';
import Construct from './components/construct.vue';
import Case from './components/case.vue';
import Empty from '@/components/Empty/Index.vue';
// 接口
import { getNewsList, getNavListConfig } from '@/service/api';
export default {
  name: 'Solution',
  components: { Industry, Pano, City, Construct, Case, Empty },
  data() {
    return {
      // 查询参数
      queryParams: {
        page: 1, // 当前页
        limit: 6, // 每页显示条数
        navId: 2
      },
      banner: [{
        title: '',
        content: ''
      }],
      industry: [{
        title: '',
        img: '',
        content: ''
      }],
      city: [{
        title: '',
        img: '',
        content: ''
      }],
      construct: [{
        title: '',
        content: '',
        img: '',
        steps: [
          {
            title: '',
            content: ''
          }]
      }],
      isShow: false,
      solutionCase: {
        title: '',
        steps: [
          {
            title: '',
            img: '',
            content: ''
          }
        ]
      },
      customerCase: {
        title: '',
        steps: [
          {
            title: '',
            img: '',
            content: ''
          }
        ]
      },
      panoData: [],
      caseData: []
    };
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      deep: true,
      handler() {
        this.getNavListConfig();
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getNewsList(this.queryParams);
      if (res.code === 200) {
        this.panoData = res.page.list;
      }
      this.queryParams.limit = 3;
      this.queryParams.navId = 42;
      const res1 = await getNewsList(this.queryParams);
      if (res1.code === 200) {
        this.caseData = res1.page.list;
      }
    },
    getNavListConfig() {
      const id = this.$route.query.id;
      getNavListConfig(id).then((res) => {
        if (this.notEmpty(res.data)) {
          this.banner = res.data.banner;
          this.industry = res.data.industry;
          this.city = res.data.city;
          this.construct = res.data.construct;
          this.solutionCase = res.data.solutionCase;
          this.customerCase = res.data.customerCase;
          this.isShow = true;
        } else {
          this.banner = {
            title: '',
            content: ''
          };
          this.industry = {
            title: '',
            img: '',
            content: ''
          };
          this.city = {
            title: '',
            img: '',
            content: ''
          };
          this.construct = {
            title: '',
            content: '',
            img: '',
            steps: [
              {
                title: '',
                content: ''
              }]
          };
          this.solutionCase = {
            title: '',
            steps: [
              {
                title: '',
                img: '',
                content: ''
              }
            ]
          };
          this.customerCase = {
            title: '',
            steps: [
              {
                title: '',
                img: '',
                content: ''
              }
            ]
          };
          this.isShow = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  /deep/ .el-carousel__container {
    height: 550px;
    img {
      display: block;
      margin: 0 auto;
      height: 100%!important;
      width: auto!important;
    }
  }
}
</style>

