<template>
  <div class="pano">
    <div class="topText">
      <div class="title">{{ tableData.title }}</div>
    </div>
    <div class="conBox">
      <div v-for="(item, index) in tableData.steps" :key="index" class="li">
        <img class="imgBlock" :src="item.img">
        <div class="title" @click="clickTitle(item)">{{ item.title }}</div>
        <div class="text">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    clickTitle(item) {
      if (item.targetUrl) {
        this.$router.push(item.targetUrl);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pano {
  .topText {
    height: 218px;
    // .title {
    //   padding-top: 110px;
    // }
  }
  .conBox {
    margin: 0 auto;
    width: 1280px;
    // height: 720px;
    display: flex;
    flex-wrap: wrap;
    .li {
      margin-right: 22px;
      width: 412px;
      height: 350px;
      border-radius: 4px 4px 4px 4px;
      &:hover {
        box-shadow: -1px 2px 10px 4px rgba(100,112,139,0.16);
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(3n + 1) {
        margin-bottom: 20px;
      }
      .imgBlock {
        width: 100%;
        height: 190px;
        border-radius: 4px;
      }
      .title, .text {
        padding: 0 26px;
      }
      .title {
        cursor: pointer;
        margin: 21px 0 6px;
        font-size: 22px;
        color: #252B3A;
        font-family: 'siyuan-medium' !important;
      }
      .text {
        font-size: 14px;
        color: #9299A5;
        line-height: 20px;
        letter-spacing: .2px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }
}
</style>
